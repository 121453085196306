import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import lughetReducer from "../features/lughets/lughetSlice"
import dialogReducer from "../features/dialogs/dialogSlice"

export const store = configureStore({
    reducer:{
        auth:authReducer,
        lughet:lughetReducer,
        dialog:dialogReducer
    },
})