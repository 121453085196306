import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Header from "./components/Header"
import Dialog from "./pages/Dialog"
import AddWord from "./pages/AddWord"
import Register from "../src/pages/Register"
import Login from "../src/pages/Login"
import AddWordPage from "./pages/AddWordPage"
import {ToastContainer} from "react-toastify";
import EditPage from "./pages/EditPage"
import DialogItems from "./pages/DialogItems"
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <>
      <Router>
        <div className="container mx-auto my-7 mt-0">
          <Header/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/addword" element={<AddWord/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/addwordpage" element={<AddWordPage/>} />
            <Route path="/editpage/:id" element = {<EditPage/>} />
            <Route path="/dialogpage" element = {<Dialog/>} />
            <Route path="/dialogitems" element = {<DialogItems/>} />

         </Routes>
      </div>
    </Router>
    <ToastContainer/>
    </>
  );
}

export default App;