import React from 'react'
import DialogForm from "../components/DialogForm"

export default function Dialog() {
  return (
    <div>
        <DialogForm/>
    </div>
  )
}
