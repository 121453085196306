import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dialogService from "./dialogService"

const initialState = {
    dialogs: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

// Add new Dialog

export const addDialog = createAsyncThunk("dialogs/create", async (dialogData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.editor.token
        return await dialogService.addDialog(dialogData, token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)


// Get current dialogs

export const getCurrentDialogs=createAsyncThunk("dialogs/getdialog", async(thunkAPI)=>{
try {
    return await dialogService.getCurrentDialog()
} catch (error) {
    const message =
            (error.response && error.response.data && error.response.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    
}

})



// Delete Dialog

export const deleteDialog = createAsyncThunk("dialogs/delete", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.editor.token
        return await dialogService.deleteDialog(id, token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)



export const dialogSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        reset: (state) => initialState,
    },

    extraReducers: (builder) => {
        builder
            .addCase(addDialog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addDialog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.dialogs.push(action.payload)
            })
            .addCase(addDialog.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getCurrentDialogs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCurrentDialogs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.dialogs=action.payload
            })
            .addCase(getCurrentDialogs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteDialog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteDialog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.dialogs=state.dialogs.filter((dialog)=>dialog._id !== action.payload.id)
            })
            .addCase(deleteDialog.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }

})


export const { reset } = dialogSlice.actions
export default dialogSlice.reducer