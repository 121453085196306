import React from 'react'

export default function ShowDialog({dialog}) {


function makeVoice(text){
    
    const utterance = new SpeechSynthesisUtterance(text);
    if (utterance.text !== text) {
        utterance.text = text
    }

    const synth = window.speechSynthesis;
    const voices = synth.getVoices();
    utterance.voice = voices[2]
    utterance.lang = "de-DE"
    utterance.rate = 1
    utterance.pitch = 1
    utterance.volume = 1
    window.speechSynthesis.speak(utterance);

}


    function hanleUtterance(e) {
        e.preventDefault();
        makeVoice(dialog.deutschq)
    }

    function hanleUtterance2(e) {
        e.preventDefault();
        makeVoice(dialog.deutscha)        
    }
    


  return (
    <div className='container max-auto flex flex-col max-w-sm bg-green-400 rounded-lg my-2'>
        <div className='flex flex-col p-2'>
            <div className='flex'>
                    <p className=''>
                            {dialog.deutschq}        
                    </p>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-6 w-6" onClick={hanleUtterance} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                </svg>
                </div>
        <p className='font-alkatip bg-green-300 p-1 rounded align-center' dir='rtl'>{dialog.uyghurq}</p>
       <div className='flex'><p>{dialog.deutscha}</p>
       <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-6 w-6" onClick={hanleUtterance2} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
        </svg>
       </div>
        
        <p className='font-alkatip bg-green-300 p-1 rounded align-center m-1' dir='rtl'>{dialog.uyghura}</p>
        </div>
    </div>
  )
}
