import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reset } from "../features/lughets/lughetSlice"
import Spinner from "./Spinner";



function EditForm() {

  const [formData, setFormData] = useState({
    deutsch: "",
    artikel: "",
    verben: "",
    uyghur: "",
    satze: "",
    uysatze: "",
  })

  const { deutsch, artikel, verben, uyghur, satze, uysatze } = formData
  const {id} = useParams()

  const navigate =useNavigate()
  const dispatch = useDispatch()

  const {isLoading, isError, loading, message}=useSelector((state)=>state.lughet)
  const { editor }=useSelector((state)=>state.auth)

  console.log(editor,"this is editor in editor")

  
  useEffect(() => {
      fetch(`/api/lughet/${id}`
      )
        .then(res => {
          if(!res.ok){
            toast.error(res.message)
            console.log(res.message, "this is res from backend")
          }
          return res.json()
        })
        .then((data => {
          const {deutsch, artikel, verben, uyghur, satze, uysatze} = data;
          setFormData({
            deutsch: isLoading || !deutsch ? "" : deutsch,
            artikel: isLoading || !artikel ? "" : artikel,
            verben: isLoading || !verben ? "" : verben, 
            uyghur: isLoading || !uyghur ? "" : uyghur,
            satze: isLoading || !uyghur ? "" : satze,
            uysatze: isLoading || uyghur ? "" : uysatze,
          })

        }))
        .catch((err) => {
          toast.error(err)
        })

        dispatch(reset())

}, [loading, isLoading, dispatch, id, isError, message ])  



  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }


  const onSubmit = (e) => {
    e.preventDefault()
    const lughetData={deutsch, artikel, verben, uyghur, satze, uysatze}
    fetch(`/api/lughet/${id}`,
    {method:"PUT",
    headers: {
      Authorization: "Bearer " + editor.token,
      'Content-Type':'application/json'
    },
    body: JSON.stringify(lughetData)
  })
  .then((res)=>{
    if(!res.ok){
      toast.error(res.message)
    }
    return res.json()
  })
  .then((data)=>{
    if(data.uyghur){
      toast("سۆز تەھرىرلەندى")
        navigate("/")
    }
  })
  }

  if(isLoading){
    return <Spinner/>
  }



  return (
    <section>
      <form onSubmit={onSubmit}>
        <div className="bg-grey-200 min-h-screen flex flex-col font-alkatip">
          <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
            <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
              <h1 className="mb-8 text-2xl text-center">لۇغەتنى تەھرىرلەڭ</h1>

              <input
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="deutsch"
                value={deutsch}
                onChange={onChange}
                placeholder="  گېرمانچە سۆز" />
              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="uyghur"
                value={uyghur}
                onChange={onChange}
                placeholder="ئۇيغۇرچە تەرجىمىسى" />
              <input
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="artikel"
                value={artikel}
                onChange={onChange}
                placeholder="Artikel" />
              <input
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="verben"
                value={verben}
                onChange={onChange}
                placeholder="verben" />

              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="satze"
                value={satze}
                onChange={onChange}
                placeholder="Deutscher Satz" />
              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="uysatze"
                value={uysatze}
                onChange={onChange}
                placeholder="ئۇيغۇرچە جۈملە" />

              <button
                type="submit"
                className="w-full text-center py-3 rounded bg-blue-700 text-white hover:bg-green-dark focus:outline-none my-1"
              >تاپشۇرۇش</button>

            </div>

            <div className="text-grey-dark mt-6">
              <p dir="rtl"><span className="bg-gray-800 text-white px-2 ">ئەسكەرتىش</span> گىرمانچە سۆزلۈك بىلەن ئۇيغۇرچە تەرجىمىسىنى بوش قالدۇرۇشقا بولمايدۇ، باشقا ئورۇنلار ئەھۋالغا ئاساسەن تولدۇرۇلسا ياكى بوش قالسا بولىدۇ</p>

            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default EditForm