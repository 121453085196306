import React from 'react'

const About = () => {
  return (
    <div dir='auto' className="container font-alkatip mx-auto my-7 mt-0">
      <div className='p-5  text-justify'>
      <h2 className='font-black text-lg'>
لۇغەت ھەققىدە
      </h2>
<p>
  <span className='font-black'>ئىسمى: &nbsp;</span>
تەسنىم گېرمانچە - ئۇيغۇرچە لۇغەت
<br/>
<span className='font-black'>قىسقا ئىسمى:&nbsp;</span>
TESNIM
<br/>
<span className='font-black'>نەشرى: &nbsp;</span>
 1.0.0
<br/>
<span className='font-black'>تورئادرېسى: &nbsp;</span>
www.tesnim.de
<br/>
<span className='font-black'>نەشرى ھوقۇقى:&nbsp;</span>
 گېرمانىيە شەرقىي تۈركىستان ياشلار تەشكىلاتىغا تەۋە
<br/>
بۇ گىرمانچە - ئۇيغۇرچە تور لۇغىتى بىر تۈركۈم پىداكارلارنىڭ خالىسانە تىرىشچانلىقنىڭ نەتىجىسى،  گېرمان تىلىدىكى باشلانغۇچ سەۋىيەدىكىلەر ئۈچۈن 4000 دىن ئارتۇق سۆزلۈك قوشۇلغان ، 2023- يىلنىڭ ئاخىرغا قەدەر ئوتتۇرا ۋە يۇقىرى سەۋىيەدىكىلەر ئۈچۈن سۆزلۈكلەر تولۇقلىنىپ 10000 غا يەتكۈزۈش پىلان قىلىندى. بۇ جەرياندا لۇغەت سۆزلۈكلىرىنى يىغىشتا يېقىندىن ياردەمدە بولغان ئابدۇراززاق مۇئەللىمگە، سۆزلەرنى قاراپ چىقىشتا ياردەمدە بولغان نۇرمۇھەممەد ئەخمەت ئەپەندىمگە ئالاھىدە رەھمىتىمىزنى بىلدۈرىمىز. كەلگۈسى بىر قانچە يىل ئىچىدە سۆزلۈك مەنبەسى تېخىمۇ مول، جۈملىلەرنى ئۆز ئىچىگە ئالغان، ئالىي سەۋىيدىكىلەر ئۈچۈن ھەم پايدىلىنىش مەنبەسى بولىدىغان گېرمانچە- ئۇيغۇرچە، ئۇيغۇرچە - گېرمانچە قوش يۆلىنىشلىك بىر قەدەر مۇكەممەل لۇغەتنى روياپقا چىقىرىش پىلان قىلىندى. ئەگەر بۇ ساھەدە خىزمەت قىلىۋاتقان ياكى بىز بىلەن ھەمكارلىشىپ خىزمەت قىلىشنى خالايدىغان قېرىنداشلار بولسا ئالاقە قىلىشىنى ئۈمىد قىلىمىز.
ئەلۋەتتە لۇغەت دەسلەپكى نۇسخا بولغانلىقى ئۈچۈن ھەرخىل خاتالىقلاردىن خالىي ئەمەس، خاتالىق بايقىغانلارنىڭ ۋاقتىدا بىزگە خەۋەر قىلىپ قويۇشۇڭلارنى سورايمىز.
<br/>
ئالاقە ئېلخەت ئادرېسى: ot.jugend.ev@gmail.com
<br/>
ھۆرمەت  بىلەن:
<br/>
گېرمانىيە شەرقىي تۈركىستان ياشلار تەشكىلاتى
</p>
</div>
    </div>
  )
}

export default About