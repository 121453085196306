import axios from "axios";

// import { toast } from "react-toastify";
const API_URL = "/api/dialog/"

//Add Dialog

const addDialog=async(dialogData, token)=>{

    
    const config = {
        headers:{
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
    }
    const response= await axios.post(API_URL, dialogData, config)

    return response.data    
}


// getCuttentDialog

const getCurrentDialog=async()=>{
    const response= await axios.get(API_URL)
    return response.data
}


// Delete dialog


const deleteDialog=async(dialogId, token)=>{

    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    }

    const response= await axios.delete(API_URL + dialogId, config)

    return response.data
    
}



const dialogService = {
    addDialog,
    deleteDialog,
    getCurrentDialog
}

export default dialogService