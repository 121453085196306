import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { reset } from "../features/lughets/lughetSlice"
import { reset } from "../features/dialogs/dialogSlice";
import Spinner from "../components/Spinner";
import { addDialog } from "../features/dialogs/dialogSlice";
import Select from 'react-select';

function DialogForm() {

  const [formData, setFormData] = useState({
    deutschq: "",
    uyghurq: "",
    deutscha: "",
    uyghura: ""
  })

  const options = [
    { value: 'people', label: 'ئادەملەر' },
    { value: 'perceptions', label: 'تونۇشۇش' },
    { value: 'body-health', label: 'سالامەتلىك' },
    { value: 'education', label: 'مائارىپ' },
    { value: 'career', label: 'كەسىپ' },
    { value: 'cultur', label: 'كۇلتۇر ۋە قېزىقىش' },
    { value: 'leisure', label: 'بوش ۋاقىت' },
    { value: 'nutrition', label: 'ئۇزۇقلۇق' },
    { value: 'food', label: 'ئاشخانا ۋە چايغانا' },
    { value: 'accommodation', label: 'تۇرالغۇ' },
    { value: 'tourism-travel', label: 'سەيلە-ساياھەت' },
    { value: 'natur-environment', label: 'تەبىئەت ۋە مۇھىت' },
    { value: 'communication-media', label: 'ئالاقەت ۋە مىدىيا' },
    { value: 'industry-tech', label: 'سانائەت، تېخنىلوگىيە ۋە تەتقىقات' },
    { value: 'society-state', label: 'جەمىئەت ۋە دۆلەت' },
    { value: 'time', label: 'ۋاقىت' },
    { value: 'weather', label: 'ھاۋا رايى' }
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const { deutschq,  uyghurq, deutscha, uyghura } = formData

  const navigate =useNavigate()
  const dispatch = useDispatch()

  const {isLoading, isError, isSuccess, message}=useSelector((state)=>state.dialog)
  

useEffect(()=>{
  if(isError){
    toast.error(message)
  }
  if(isSuccess){
    navigate("/dialogpage")
    toast("دىئالوگ قوشۇلدى")
  }

  dispatch(reset())

}, [isLoading, isError, isSuccess, message, navigate, dispatch])  



  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }



  const onSubmit = (e) => {
    e.preventDefault()
    const dialogData={deutschq, uyghurq, deutscha, uyghura, selectedOption }
    dispatch(addDialog(dialogData))
    setFormData({ 
    deutschq: "",
    uyghurq: "",
    deutscha: "",
    uyghura: "",
  
  })
    setSelectedOption(null)
  }

  if(isLoading){
    return <Spinner/>
  }

  return (
    <section>
      <form onSubmit={onSubmit}>
        <div className="bg-grey-200 min-h-screen flex flex-col font-alkatip">
          <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
            <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
              <h1 className="mb-8 text-2xl text-center">دىئالوگ قوشۇڭ</h1>

              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="deutschq"
                value={deutschq}
                onChange={onChange}
                placeholder="Bitte betreten Sie den Dialog." />
              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="uyghurq"
                value={uyghurq}
                onChange={onChange}
                placeholder="دىئالوگنىڭ ئۇيغۇرچە تەرجىمىسىنى كىرگۈزۈڭ" />
                <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="deutscha"
                value={deutscha}
                onChange={onChange}
                placeholder="Bitte betreten Sie den Dialog." />
              <textarea
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="uyghura"
                value={uyghura}
                onChange={onChange}
                placeholder="دىئالوگنىڭ ئۇيغۇرچە تەرجىمىسىنى كىرگۈزۈڭ" />


          <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
           />
             

              <button
                type="submit"
                className="w-full text-center py-3 mt-5 rounded bg-blue-700 text-white hover:bg-green-dark focus:outline-none my-1"
              >تاپشۇرۇش</button>

            </div>

          </div>
        </div>
      </form>


    </section>
  )
}

export default DialogForm