import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authService from "./authService"

//Get editor from localStorage
const editor = JSON.parse(localStorage.getItem("editor"))

const initialState = {
    editor: editor ? editor : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

//Register user

export const register = createAsyncThunk("auth/register", async (editor, thunkAPI) => {
    try {
        return await authService.register(editor)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.message) ||
        error.message ||
        error.toString()
        return thunkAPI.rejectWithValue(message) 
    }

})
//Login user

export const login = createAsyncThunk("auth/login", async (editor, thunkAPI) => {
    try {
        return await authService.login(editor)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.message) ||
        error.message ||
        error.toString()
        return thunkAPI.rejectWithValue(message) 
    }

})

export const logout= createAsyncThunk("auth/logout",
async()=>{
await authService.logout()
})


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""

        }

    },
    extraReducers: (builder) => {
        builder
        .addCase(register.pending, (state)=>{
            state.isLoading=true
        })
        .addCase(register.fulfilled, (state, action)=>{
            state.isLoading=false
            state.isSuccess=true
            state.editor=action.payload
        })
        .addCase(register.rejected, (state, action)=>{
            state.isLoading=false
            state.isError=true
            state.message=action.payload
        })
        .addCase(login.pending, (state)=>{
            state.isLoading=true
        })
        .addCase(login.fulfilled, (state, action)=>{
            state.isLoading=false
            state.isSuccess=true
            state.editor=action.payload
        })
        .addCase(login.rejected, (state, action)=>{
            state.isLoading=false
            state.isError=true
            state.message=action.payload
        })
        .addCase(logout.fulfilled, (state)=>{state.editor=null})
     }

})

export const { reset } = authSlice.actions

export default authSlice.reducer