import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddWordForm from "../components/AddWordForm";


function AddWordPage() {

  const navigate = useNavigate()

  const { editor } = useSelector((state) => state.auth)

  useEffect(() => {

    if (!editor) {
      navigate("/login")
    }

  }, [editor, navigate])



  return (
    <>
      <h1 className="mt-6 text-2xl text-center font-alkatip uppercase" >{editor.name} ئەپەندىم خوش كەلدىڭىز </h1>
      <AddWordForm/>
    </>
  )
}

export default AddWordPage