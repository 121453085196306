import React from 'react'
import EditForm from '../components/EditForm'
import Header from '../components/Header'



export default function EditPage() {
  return (
    <>
    <EditForm/>
    </>
  )
}
