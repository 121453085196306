import Search from "../components/Search"

const Home = () => {
  return (
    <div>
      <Search/>
    </div>
  )
}

export default Home
