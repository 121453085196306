import React from 'react'
import { useEffect, useState } from 'react'
import Select from 'react-select';
import { useSelector,  useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ShowDialog from '../components/ShowDialog';
import Spinner from "../components/Spinner"


export default function DialogItems() {

  const [selectedOption, setSelectedOption] = useState(null);   
  const [dialogs, setDialogs] = useState([])

  const dispatch = useDispatch()

  const {isLoading, isError, isSuccess, message}=useSelector((state)=>state.dialog) 

  const options = [
    { value: 'people', label: 'ئادەملەر' },
    { value: 'perceptions', label: 'تونۇشۇش' },
    { value: 'body-health', label: 'سالامەتلىك' },
    { value: 'education', label: 'مائارىپ' },
    { value: 'career', label: 'كەسىپ' },
    { value: 'cultur', label: 'كۇلتۇر ۋە قېزىقىش' },
    { value: 'leisure', label: 'بوش ۋاقىت' },
    { value: 'nutrition', label: 'ئۇزۇقلۇق' },
    { value: 'food', label: 'ئاشخانا ۋە چايغانا' },
    { value: 'accommodation', label: 'تۇرالغۇ' },
    { value: 'tourism-travel', label: 'سەيلە-ساياھەت' },
    { value: 'natur-environment', label: 'تەبىئەت ۋە مۇھىت' },
    { value: 'communication-media', label: 'ئالاقەت ۋە مىدىيا' },
    { value: 'industry-tech', label: 'سانائەت، تېخنىلوگىيە ۋە تەتقىقات' },
    { value: 'society-state', label: 'جەمىئەت ۋە دۆلەت' },
    { value: 'time', label: 'ۋاقىت' },
    { value: 'weather', label: 'ھاۋا رايى' }
  ];

   useEffect(()=>{
    
  if(isError){
    toast.error(message)
  }

if(selectedOption){
  fetch(`/api/dialog/?q=${selectedOption.value}`)
  .then(res => {
    if (!res.ok) {
      toast.error("خاتالىق بايقالدى")
    }
    return res.json()
  })
  .then((data => {
    setDialogs(data)
  }))
  .catch((err) => {
    toast.error(err)
  })

}

if(isLoading){
  return <Spinner/>
}

   }, [isLoading, selectedOption, isError, isSuccess, message, dispatch])


  return (
    
    <>
     <form>
    <div className="bg-grey-200 flex flex-col font-alkatip">
          <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center mt-3 px-2">
            <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
  
      <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
           />         
      </div>
      </div>
      </div>
      </form>

      <div className='flex flex-col' >
        {dialogs.length > 0 && (<div className='container max-w-sm mx-auto flex flex-col items-center mt-3 px-2'>
          {dialogs.map((dialog)=>(<ShowDialog key={dialog._id } dialog={dialog}/>) )}
        </div>) }
      </div>

     

    </>
    
   
  )
}
